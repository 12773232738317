import { readItem, readItems } from '@directus/sdk';
import { debounce } from 'lodash-es';
import type { MaybeRef } from 'vue';
import type { DirectusFile, News, NewsCategory } from '@/plugins/cms';

export interface NewsItem {
  id: any;
  date_published: Date;
  title: string;
  body: string;
  categories: NewsCategory[];
  featuredImage?: DirectusFile;
  photos: DirectusFile[];
}

export function useNewsCategories() {
  const { cms } = useCms();
  return useAsyncData(
    'cmla_news_categories',
    async () => {
      return await cms.request(
        readItems('cmla_news_categories', { fields: ['*'], sort: 'name' }),
      );
    },
    { default: () => [] },
  );
}

export function useNewsList(opts: {
  sort?: MaybeRef<string | undefined>;
  category?: MaybeRef<number | undefined>;
  search?: MaybeRef<string | undefined>;
  limit?: MaybeRef<number | undefined>;
  offset?: MaybeRef<number | undefined>;
  page?: MaybeRef<number | undefined>;
}) {
  const { cms, convertAssetUrl } = useCms();
  const asyncData = useAsyncData(
    'cmla_news',
    async () => {
      const data = await cms.request(
        readItems('cmla_news', {
          fields: [
            '*',
            'categories.cmla_news_categories_id.*',
            'featuredImage.*',
          ],
          filter: {
            categories:
              unref(opts.category) != null
                ? ({
                    cmla_news_categories_id: { _eq: unref(opts.category) },
                  } as any)
                : undefined,
          },
          sort: unref(opts.sort) as any,
          search: unref(opts.search),
          limit: unref(opts.limit),
          offset: unref(opts.offset),
          page: unref(opts.page),
        }),
      );
      return data.map((x) => convertSchema(x));
    },
    {
      watch: [
        toRef(opts.category),
        toRef(opts.sort),
        toRef(opts.limit),
        toRef(opts.offset),
        toRef(opts.page),
      ],
    },
  );

  const debouncedRefresh = debounce(asyncData.refresh, 500);
  watch([toRef(opts.search)], () => debouncedRefresh());

  return asyncData;
}

export function useNewsItem(id: MaybeRef<number>) {
  const { cms } = useCms();
  return useAsyncData(
    `cmla_news/${unref(id)}`,
    async () => {
      const data = await cms
        .request(
          readItem('cmla_news', unref(id), {
            fields: [
              '*',
              'categories.cmla_news_categories_id.*',
              'featuredImage.*',
              'photos.directus_files_id.*',
            ],
          }),
        )
        .catch(() => null);
      return data && convertSchema(data);
    },
    { watch: [toRef(id)] },
  );
}

function convertSchema(data: News): NewsItem {
  return {
    ...data,
    date_published: new Date(data.date_published),
    categories: data.categories.map((x: any) => x.cmla_news_categories_id),
    featuredImage: data.featuredImage,
    photos: data.photos.map((x: any) => {
      return x?.directus_files_id;
    }),
  } as NewsItem;
}
